<template>
  <Popup
    :title="`${typeText} 차단 해제`"
    :maxWidth="800"
    closeBtnText="닫기"
    :compeleteBtnText="`${typeText} 차단 해제하기`"
    @onClickClose="$emit('onClickClose')"
    @onClickComplete="$emit('onClickComplete',reason)">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:160px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>사유</th>
          <td>
            <Textarea
              :placeholder="`${typeText} 차단 해제 사유 (필수 아님)`"
              :showLine="3"
              :value.sync="reason"
              :max="200"
              :isCount="true"/>
          </td>
        </tr>
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';

export default {
  name:'BlackReasonPopup',
  props:{
    typeText: String
  },
  components:{
    Popup,
    TableView,
    Textarea
  },
  data(){
    return{
      reason:''
    }
  },
}
</script>