<template>
  <PageWithLayout>
    <search
      :searchDataList.sync="viewModel.searchData"
      :searchParams.sync="viewModel.searchParams"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.searched && !viewModel.hasData ? '검색결과가 없습니다' : '반송 기간을 검색해주세요'"
      @onSearch="viewModel.onSearch()"
      >
    </Board>
    <template v-slot:popup>
      <BlackReasonPopup
        v-if="viewModel.isBlockPopup"
        :typeText="viewModel.detailData.blacklistType | convertIdToText('cs_block_type_sort')"
        @onClickClose="viewModel.onClickCloseBlackReasonPopup()"
        @onClickComplete="(reason) => viewModel.onClickCompleteBlackReasonPopup(reason)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// popup
import BlackReasonPopup from '@/views/cs/blacklist/view/popup/BlackReasonPopup'
// viewModel
import BlackListSearchViewModel from '@/views/cs/blacklist/viewModel/BlackListSearchViewModel'
import { convertIdToText } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils';

export default {
  name: 'BlackListSearch',
  components:{
    PageWithLayout,
    Search,
    Board,
    BlackReasonPopup
  },
  beforeMount(){
    this.viewModel.init()
  },
  data(){
    return{
      viewModel: new BlackListSearchViewModel(),
    }
  },
}
</script>
<style scoped>
.board_comm::v-deep .tbl_comm table{min-width:100%;width:auto}
.board_comm::v-deep .tbl_comm table .td_mark .txt_tbl{display:inline-block}
</style>