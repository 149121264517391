import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import router from '@/router';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';

export default class BlackListSearchViewModel {
  constructor() {
    this.dataList = [];
    this.searchData = [
      {
        title: '퍼핀 회원 UID',
        type: 'Input',
        grid: 100,
        isFull: true,
        value: '',
      },
    ];
    this.searchParams = {
      page: 0,
      pageSize: 10,
      direction: 'DESC',
    };
    this.paginationData = {
      totalCount: 0,
      totalPage: 0,
    };
    this.boardData = {
      title: '블랙리스트 조회',
      drawDataList: [
        {
          title: 'ID',
          width: '80px',
          value: 'id',
          isAlignLeft: true,
        },
        {
          title: '이름',
          width: '68px',
          value: 'user_name',
          isAlignLeft: true,
        },
        {
          title: '퍼핀 회원 UID',
          width: '68px',
          value: 'firfin_user_uid',
          isAlignLeft: true,
        },
        {
          title: '차단범위',
          width: '120px',
          value: 'blacklist_type',
          filter: {
            name: 'convertIdToText',
            value: 'cs_block_type',
          },
          class: {
            classColorFilter:{
              name:'convertIdToColor',
              dataListName:'cs_block_type',
              prev:'tc'
            },
          },
        },
        {
          title: '상태',
          width: '90px',
          value: 'blocked',
          filter: {
            name: 'convertIdToText',
            value: 'cs_block_boolean_status',
          },
          badge: {
            badgeColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'cs_block_boolean_status',
            },
          },
        },
        {
          title: '차단 기한',
          width: '104px',
          dateRangeValue: {
            startValue: 'start_at',
            endValue: 'end_at',
            betweenText: ' ~<br/>',
            isEndPastDateToRed: true,
          },
          isEllip: true,
          isAlignLeft: true,
        },
        {
          title: '사유',
          width: '',
          value: 'registered_reason',
          isAlignLeft: true,
        },
        {
          title: '차단 등록자',
          width: '64px',
          value: 'registered_by',
          isAlignLeft: true,
          isEllip: true,
        },
        {
          title: '차단 해제',
          width: '80px',
          value: 'blocked',
          buttonCondition: [
            {
              condition: true,
              text: '해제',
              size: 'small',
              style: 'secondary_border',
              disabled: false,
              onClickEvent: data => this.onClickBlockDelete(data),
            },
          ],
        },
        {
          title: '회원 상세',
          width: '100px',
          value: 'blocked',
          button: { text:'회원 상세', size:'small', style:'secondary_border', onClickEvent: (data) => this.onClickGotoUserSearch(data) },
        },
      ],
      option: {
        isTotal: true,
        isSize: false,
        rowIdValue: 'id',
      },
    };
    this.searched = false;
    this.hasData = false;

    this.isBlockPopup = false;
    this.detailData = {};
  }

  init() {
    this.getBlackList();
  }
  onSearch() {
    this.getBlackList();
  }
  onClickBlockDelete(data) {
    this.detailData = data;
    this.isBlockPopup = true;
  }
  onClickCloseBlackReasonPopup(){
    this.isBlockPopup = false;
  }
  onClickCompleteBlackReasonPopup(reason){
    this.postBlockDelete(reason);
  }
  onClickGotoUserSearch(data){
    router.push({ name: 'CS_USER_BYUSERDATA_LIST', query: { tab: 'UID', value: data.firfin_user_uid } });
  }
  // [API] 블랙리스트 차단 내역 조회
  getBlackList() {
    this.searched = true;
    const query = makeQueryStringByObject({ 'firfinUserUid' : this.searchData[0].value });
    const path = `${apiPath.CS_BLOCK_HISTORY}${query}`;
    GET_REQUEST(path).then(
      success => {
        const resultData = success.data;
        this.dataList = resultData.body;
        this.hasData = resultData.body.length > 0;

        this.paginationData.totalCount = resultData.body.length;
        // this.paginationData.totalPage = resultData.pagination.total_page;
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
  // [API] 차단 내역 해제
  postBlockDelete(reason){
    const path = `${apiPath.CS_BLOCK_DELETE.format(this.detailData.uid)}`;
    const body =  {
      body:{
        "reason": reason,
      },
      header:{
        request_at: new Date()
      }
    }
    POST_REQUEST(path,body).then(
    (success) => {
      // const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', `해당 차단내용이 해제되었습니다`);
      this.isBlockPopup = false;
      this.getBlackList()
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}
